
import { defineComponent, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsPassword from '@web/components.v2/Settings/Password/index.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'

import i18n from '@web/plugins/i18n'
import Swal from 'sweetalert2'
import { useStore } from '@web/store'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'

export default defineComponent({
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsHeaderMain,
    AsMenu,
    AsFooter,
    AsSpacer,
    AsPassword,
    AsBreadcrumb
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const title = i18n.global.t('crumb.editPassword')
    const menu = computed(() => store.getters['menu/general'])

    useMeta({
      title
    })

    function submit () {
      Swal.fire({
        icon: 'success',
        title: i18n.global.t('passwordSuccessUpdate')
      })
    }

    function goBack () {
      router.push({ name: 'userSettings' })
    }

    return {
      title,
      menu,

      submit,
      goBack
    }
  }
})
