import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "acNotice",
  "data-cy": "notice"
}
const _hoisted_2 = {
  key: 0,
  class: "acNotice_list"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      (Array.isArray(_ctx.notice) && _ctx.notice.length > 0)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notice, (note, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: "acNotice_item"
              }, _toDisplayString(note), 1))
            }), 128))
          ]))
        : (_ctx.notice && _ctx.notice.length > 0)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.notice), 1)
            ], 64))
          : _createCommentVNode("", true)
    ])
  ]))
}