import { ref, computed } from 'vue'
import i18n from '@web/plugins/i18n'

function usePassword (mobile?: boolean) {
  const currentPassword = ref('')
  const password = ref('')
  const passwordRepeat = ref('')
  const notice = ref<string[] | string>('')
  const save = ref(true)
  const showPasswordOld = ref(false)
  const showPasswordNew = ref(false)
  const showPasswordAgain = ref(false)

  const button = computed(() => {
    if (mobile) {
      return save.value ? i18n.global.t('saveChanges') : i18n.global.t('savingChanges')
    }
    return save.value ? i18n.global.t('save') : i18n.global.t('saving')
  })

  function getErrorPasswordRepeat ({ errors, failedRules, serverErrors = [] }: { errors: unknown[]; failedRules: Dictionary<unknown>; serverErrors }) {
    if (failedRules.confirmed) {
      return i18n.global.t('error.newPasswordSameAsPassword')
    }
    if (errors.length > 0) {
      return errors[0]
    } else if (serverErrors.length > 0 && serverErrors.password_repeat.length > 0) {
      return serverErrors.password_repeat[0]
    }
    return ''
  }

  function clear () {
    notice.value = []
  }

  return {
    currentPassword,
    password,
    passwordRepeat,
    notice,
    save,
    showPasswordOld,
    showPasswordNew,
    showPasswordAgain,
    button,

    getErrorPasswordRepeat,
    clear
  }
}

export default usePassword
