import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "password",
  ref: "refAsPassword"
}
const _hoisted_2 = { class: "password_title" }
const _hoisted_3 = { class: "password_row" }
const _hoisted_4 = { class: "password_row" }
const _hoisted_5 = { class: "password_row" }
const _hoisted_6 = { class: "password_row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ac_notice = _resolveComponent("ac-notice")!
  const _component_ac_input = _resolveComponent("ac-input")!
  const _component_v_field = _resolveComponent("v-field")!
  const _component_ac_button = _resolveComponent("ac-button")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_settings_content = _resolveComponent("settings-content")!

  return (_openBlock(), _createBlock(_component_settings_content, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1),
        _createVNode(_component_ac_notice, { notice: _ctx.notice }, null, 8, ["notice"]),
        _createVNode(_component_v_form, {
          class: "password_form",
          onSubmit: _ctx.submit
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.type === 'change')
                ? (_openBlock(), _createBlock(_component_v_field, {
                    key: 0,
                    modelValue: _ctx.currentPassword,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.currentPassword) = $event)),
                    modelModifiers: { trim: true },
                    name: "current_password",
                    label: _ctx.$t('oldPassword'),
                    rules: "required|min:6"
                  }, {
                    default: _withCtx(({ errors }) => [
                      _createVNode(_component_ac_input, {
                        name: "current_password",
                        error: _ctx.getFirstError('current_password', errors),
                        modelValue: _ctx.currentPassword,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPassword) = $event)),
                        modelModifiers: { trim: true },
                        label: _ctx.$t('oldPassword'),
                        placeholder: _ctx.$t('input.oldPassword'),
                        "append-icon": _ctx.showPasswordOld ? 'visibility' : 'visibilityOff',
                        type: _ctx.showPasswordOld ? 'text' : 'password',
                        "onClick:append": _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPasswordOld = !_ctx.showPasswordOld))
                      }, null, 8, ["error", "modelValue", "label", "placeholder", "append-icon", "type"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_v_field, {
                name: "passwordField",
                label: _ctx.$t('newPassword'),
                modelValue: _ctx.password,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.password) = $event)),
                modelModifiers: { trim: true },
                rules: "required|min:6"
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_ac_input, {
                    name: "passwordField",
                    error: _ctx.getFirstError('password', errors),
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.password) = $event)),
                    modelModifiers: { trim: true },
                    label: _ctx.$t('newPassword'),
                    placeholder: _ctx.$t('input.newPassword'),
                    ref: "passwordField",
                    "append-icon": _ctx.showPasswordNew ? 'visibility' : 'visibilityOff',
                    type: _ctx.showPasswordNew ? 'text' : 'password',
                    "onClick:append": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showPasswordNew = !_ctx.showPasswordNew))
                  }, null, 8, ["error", "modelValue", "label", "placeholder", "append-icon", "type"])
                ]),
                _: 1
              }, 8, ["label", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_v_field, {
                modelValue: _ctx.passwordRepeat,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.passwordRepeat) = $event)),
                modelModifiers: { trim: true },
                name: "passwordField_repeat",
                label: _ctx.$t('newPasswordAgain'),
                rules: "required|min:6|confirmed:@passwordField",
                ref: "passwordRepeatProvider"
              }, {
                default: _withCtx(({ errors }) => [
                  _createVNode(_component_ac_input, {
                    name: "passwordField_repeat",
                    error: _ctx.getErrorPasswordRepeat({ errors, failedRules: {}, serverErrors: _ctx.serverErrors }),
                    modelValue: _ctx.passwordRepeat,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.passwordRepeat) = $event)),
                    modelModifiers: { trim: true },
                    label: _ctx.$t('newPasswordAgain'),
                    placeholder: _ctx.$t('input.newPasswordAgain'),
                    "append-icon": _ctx.showPasswordAgain ? 'visibility' : 'visibilityOff',
                    type: _ctx.showPasswordAgain ? 'text' : 'password',
                    "onClick:append": _cache[7] || (_cache[7] = ($event: any) => (_ctx.showPasswordAgain = !_ctx.showPasswordAgain))
                  }, null, 8, ["error", "modelValue", "label", "placeholder", "append-icon", "type"])
                ]),
                _: 1
              }, 8, ["modelValue", "label"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ac_button, {
                class: "password_button",
                "data-text": _ctx.button,
                type: "submit",
                block: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.button), 1)
                ]),
                _: 1
              }, 8, ["data-text"])
            ])
          ]),
          _: 1
        }, 8, ["onSubmit"])
      ], 512)
    ]),
    _: 1
  }))
}