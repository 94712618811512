
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AcNotice',
  props: {
    notice: {
      type: [String, Array],
      default: ''
    }
  }
})
