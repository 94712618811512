
import { defineComponent } from 'vue'

import { Form as VForm, Field as VField } from 'vee-validate'

import SettingsContent from '@web/components.v2/Settings/Content.vue'
import AcInput from '@ui-kit/components/AcInput.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import AcNotice from '@ui-kit/components/AcNotice.vue'

import UserApi from '@web/api/modules/User'
import i18n from '@web/plugins/i18n'

import useErrors from '@web/composition/useErrors'
import usePassword from '@web/composition/usePassword'
import Logger from '@web/common/Logger'

export default defineComponent({
  name: 'Password',
  components: {
    SettingsContent,
    AcButton,
    AcInput,
    AcNotice,
    VForm,
    VField
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'change' // change | reset
    }
  },
  emits: ['submit'],
  setup (props, ctx) {
    const {
      serverErrors,
      displayServerErrors,
      getFirstError
    } = useErrors()

    const {
      currentPassword,
      password,
      passwordRepeat,
      notice,
      save,
      showPasswordOld,
      showPasswordNew,
      showPasswordAgain,
      button,

      getErrorPasswordRepeat,
      clear
    } = usePassword()

    async function submit () {
      clear()

      try {
        if (!save.value) return

        const data: {
          password: string;
          // eslint-disable-next-line camelcase
          current_password?: string;
          // eslint-disable-next-line camelcase
          password_repeat?: string;
        } = { password: password.value }

        if (props.type === 'change') {
          data.current_password = currentPassword.value
        } else {
          data.password_repeat = passwordRepeat.value
        }

        save.value = false

        await UserApi.password(data)
        ctx.emit('submit')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (e.errors) {
          serverErrors.value = e.errors
          notice.value = displayServerErrors(e.errors)
        } else {
          notice.value = i18n.global.t('error.password')
        }
        Logger.log(e)
      } finally {
        save.value = true
      }
    }

    return {
      notice,
      password,
      passwordRepeat,
      currentPassword,
      showPasswordNew,
      showPasswordOld,
      showPasswordAgain,
      button,

      serverErrors,
      getFirstError,

      submit,
      getErrorPasswordRepeat
    }
  }
})
